<template>
    <div class="content-main">
        <img class="img" src="../assets/qiyedongcha.jpg" />
    </div>
</template>

<script>
export default {
}
</script>

<style scoped>
.content-main img {
    width: 100%;
    height: 633px;
}
</style>